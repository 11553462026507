import * as React from "react"
import PropTypes from "prop-types"


const CaseStudyTestimonial = (props) => (
  <div className="flex flex-col sm:flex-row items-center border-white bg-zycadaDarkPurple rounded-3xl p-3 mb-8">
    <div className={"mx-1 sm:mx-4 w-4/5 rounded-3xl flex flex-col text-start"}>
      <h4 className={"my-auto text-white"}>{props.testimonial}</h4>
      {props.customerName && <h4 className={"my-auto text-white py-2"}>- {props.customerName}</h4>}
      {props.customerTitle && <h5 className={"my-auto text-white py-2"}> {props.customerTitle}</h5>}
    </div>
    <div className="hidden sm:flex flex-col sm:flex-row w-1/5 items-center justify-center border border-transparent rounded-3xl overflow-hidden ">
      <img className={"mb-0"} src={props.imgURL} alt={props.altText}/>
    </div>
  </div>
)


CaseStudyTestimonial.propTypes = {
  testimonial: PropTypes.string,
  imgURL: PropTypes.string,
  altText: PropTypes.string,
  customerName: PropTypes.string,
  customerTitle: PropTypes.string,
}

CaseStudyTestimonial.defaultProps = {
  testimonial: ``,
  imgURL: ``,
  altText: ``,
  customerName: ``,
  customerTitle: ``,
}

export default CaseStudyTestimonial
