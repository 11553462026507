import * as React from "react"
import PropTypes from "prop-types"

const ResultsBlock = (props) => (
  props.boost1 &&
  <div className={"flex flex-col justify-center mb-4"}>
    <div className="hidden md:block sm:flex-col w-64 px-4 mx-4 text-center">
      <h4 className={"uppercase text-zycadaDarkPurple"}>Results</h4>
      <div className={"text-center text-white py-1 border border-white rounded-2xl bg-klean-gradient-bg"}>
      <h2 className={"text-center mb-0"}>{props.boost1}</h2>
      <h3 className={"text-center mb-0"}>{props.boost1Name}</h3>
      </div>
      <br />
      {props.boost2 && <div className={"text-center text-white py-1 border border-white rounded-2xl bg-klean-gradient-bg"}>
      {props.boost2 && <h2 className={"text-center mb-0"}>{props.boost2}</h2>}
      {props.boost2Name && <h3 className={"text-center mb-0"}>{props.boost2Name}</h3>}
      </div>}
      <br />
      {props.summaryText && <h4 className={"text-center text-orange"}>{props.summaryText}</h4>}
    </div>

    <div className="block md:hidden w-full p-4 my-4 items-start text-center">
      <h4 className={"uppercase text-zycadaDarkPurple"}>Results</h4>
      <div className={"flex justify-center"}>
        <div className={"min-w-1/2"}>
      <h2 className={"text-center text-white py-1 border border-white rounded-2xl bg-klean-gradient-bg w-4/5 mx-auto"}>{props.boost1}</h2>
      <h3 className={"text-center"}>{props.boost1Name}</h3>
        </div>
        {props.boost2 &&  <div className={"min-w-1/2"}>
      <h2 className={"text-center text-white py-1 border border-white rounded-2xl bg-klean-gradient-bg w-4/5 mx-auto"}>{props.boost2}</h2>
      {props.boost2Name && <h3 className={"text-center"}>{props.boost2Name}</h3>}
        </div>}
      </div>
      {props.summaryText && <h4 className={"text-center text-orange"}>{props.summaryText}</h4>}

    </div>
  </div>
)

ResultsBlock.propTypes = {
  boost1: PropTypes.string,
  boost2: PropTypes.string,
  boost1Name: PropTypes.string,
  boost2Name: PropTypes.string,
  summaryText: PropTypes.string,

}

ResultsBlock.defaultProps = {
  boost1: ``,
  boost2: ``,
  boost1Name: ``,
  boos21Name: ``,
  summaryText: ``,
}


export default ResultsBlock

