import { Formik } from "formik"
import { navigate } from "gatsby"
import * as Yup from "yup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faAsterisk } from "@fortawesome/free-solid-svg-icons"
import Button from "./button"
import * as React from "react"

const validate = values => {
  const errors = {}
  if (values.email === null) {
    errors.email = "Required"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address"
  }

  return errors
}

const fieldStyles =
  "w-full rounded-none border-b-2 border-b-slate-100 bg-transparent focus:rounded-none focus:outline-none mb-4 py-2"

const fullWidthFieldStyles =
  "min-w-full rounded-none border-b-2 border-b-slate-100 bg-transparent focus:rounded-none focus:outline-none mb-4 py-2"

const containsFreeEmail = string =>
  /(hotmail.com)|(gmail.com)|(outlook.com)|(yahoo.com)|(aol.com)|(protonmail.com)/.test(
    string
  )

const NewEvalForm = () => {
  let emailCheck

  if (typeof window !== "undefined") {
    if (document.referrer.indexOf(window.location.hostname) !== -1) {
      console.log("history undefined")
    } else {
      if (
        typeof JSON.stringify(window.history.state?.submittedEmail) !==
        "undefined"
      ) {
        emailCheck = JSON.parse(
          JSON.stringify(window.history.state.submittedEmail.email)
        )
      }

      // if (emailCheck) {
      //   initialValues = {
      //     "name": "email",
      //     "value": emailCheck,
      //   }
      // }
    }
  }

  return (
    <Formik
      initialValues={{ email: emailCheck }}
      onSubmit={async (values, onsubmitProps, setStatus) => {
        await new Promise(resolve => setTimeout(resolve, 500))
        // alert(JSON.stringify(values, null, 2));
        const completedFormValues = values
        console.log(JSON.stringify(values, null, 2))
        const url =
          "https://api.hsforms.com/submissions/v3/integration/submit/3776214/2fd200e3-17a8-46f8-9c2d-e0c3f5185289"
        const xhr = new XMLHttpRequest()

        const dataToSendToHubspot = {
          fields: [
            {
              name: "website",
              value: values.website,
            },
            {
              name: "email",
              value: values.email,
            },
            {
              name: "phone",
              value: values.phone,
            },
            {
              name: "firstname",
              value: values.firstName,
            },
            {
              name: "lastname",
              value: values.lastName,
            },
          ],
          context: {
            pageUri: "www.zycada.com/free-site-evaluation",
            pageName: "Free Site Evaluation",
          },
        }

        const final_data = JSON.stringify(dataToSendToHubspot, null, 2)
        console.log(final_data)
        xhr.open("POST", url)
        xhr.setRequestHeader("Content-Type", "application/json")
        // xhr.onreadystatechange = function () {
        //   if (xhr.readyState === 4 && xhr.status === 200) {
        //     alert(xhr.responseText); // Returns a 200 response if the submission is successful.
        //   } else if (xhr.readyState === 4 && xhr.status === 403) {
        //     alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
        //   } else if (xhr.readyState === 4 && xhr.status === 404) {
        //     alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
        //   }
        // }
        // Sends the request
        xhr.send(final_data)

        navigate("/continue", {
          state: { completedFormValues },
        })
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Please enter a valid email.")
          // .required("Email is required.")
          // .test("isPreFilledEmail", "Email is required", value => {
          //     if (!value) {
          //       return false
          //     }
          //   }
          // )
          .test(
            "Please use your work email.",
            "Please use your work email.",
            value => !containsFreeEmail(value)
          ),
        firstName: Yup.string().required(""),
        lastName: Yup.string().required(""),
        phone: Yup.string().required(""),
        website: Yup.string().required(""),
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props
        return (
          <form onSubmit={handleSubmit} className={"mb-0"}>
            <label htmlFor="email" style={{ display: "block" }}></label>
            <div className={"flex flex-col p-6"}>
              <div className={"flex flex-row flex-wrap justify-around"}>
                <input
                  name={"email"}
                  id="email"
                  placeholder="Work email..."
                  type="text"
                  defaultValue={emailCheck}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={fullWidthFieldStyles}
                />

                {/*<FontAwesomeIcon icon={faAsterisk} className={"absolute text-orange"} size={"xs"}/>*/}
              </div>
              <div className={"flex flex-row flex-wrap justify-around"}>
                  <div className={"w-full md:w-1/2"}>
                  <input
                    id="firstName"
                    placeholder="First Name..."
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={fieldStyles}
                  />

                  {/*<FontAwesomeIcon icon={faAsterisk} className={"absolute text-orange"} size={"xs"}/>*/}
                </div>
                  <div className={"w-full md:w-1/2"}>
                  <input
                    id="lastName"
                    placeholder="Last Name..."
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={fieldStyles}
                  />
                  &nbsp;
                  {/*<FontAwesomeIcon icon={faAsterisk} className={"absolute text-orange"} size={"xs"}/>*/}
                </div>
              </div>
              <div className={"flex flex-row flex-wrap justify-between"}>
                  <div className={"w-full md:w-1/2"}>
                  <input
                    name={"website"}
                    id="website"
                    placeholder="Website..."
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      fieldStyles
                      // errors.email && touched.email
                      //   ? "text-input error"
                      //   : "text-input"
                    }
                  />
                  {/*<FontAwesomeIcon icon={faAsterisk} className={"absolute text-orange"} size={"xs"}/>*/}
                </div>

                  <div className={"w-full md:w-1/2"}>
                  <input
                    id="phone"
                    placeholder="Phone..."
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={fieldStyles}
                  />
                </div>
              </div>
              <div className={"flex"}>
                <button
                  className={
                    "relative text-white  p-2 h-10 bg-btn-klean border  border-solid border-transparent rounded-md focus:outline-none hover:-translate-y-1 font-bold transition ease-in-out delay-150 duration-300 "
                  }
                  type="submit" disabled={isSubmitting}
                >

                  Get In Touch &nbsp;&nbsp;
                  <FontAwesomeIcon icon={faArrowRight} />
                  &nbsp;&nbsp;
                </button>
              </div>
            </div>
            <div className={"flex flex-col"}>
              {errors.email && touched.email && (
                <div className="text-center">
                  <br />
                  <p className={"text-orange"}>{errors.email}</p>
                </div>
              )}
              {errors.website && touched.website && (
                <div className="text-center">
                  <br />
                  <p className={"text-orange"}>{errors.website}</p>
                </div>
              )}
              {errors.phone && touched.phone && (
                <div className="text-center">
                  <br />
                  <p className={"text-orange"}>{errors.phone}</p>
                </div>
              )}
              {errors.firstName && touched.firstName && (
                <div className="text-center">
                  <br />
                  <p className={"text-orange"}>{errors.firstName}</p>
                </div>
              )}
              {errors.lastName && touched.lastName && (
                <div className="text-center">
                  <br />
                  <p className={"text-orange"}>{errors.lastName}</p>
                </div>
              )}
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

export default NewEvalForm
