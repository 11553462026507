import * as React from "react"
import PropTypes from "prop-types"

const CaseStudyTitle = props => (
  <div className={"md:flex items-center text-center md:text-left"}>
    <div className={"flex md:w-1/5 items-center justify-center"}>
    <img className={""} src={props.imgURL} alt={props.altText} />
    </div>
    <div className={"md:pl-6 md:w-4/5"}>
    <h1 className={"text-zycadaDarkPurple"}>{props.introTitle}</h1>
    </div>
  </div>
)

CaseStudyTitle.propTypes = {
  introTitle: PropTypes.string,
  imgURL: PropTypes.string,
}

CaseStudyTitle.defaultProps = {
  introTitle: ``,
  imgURL: ``,
}

export default CaseStudyTitle
