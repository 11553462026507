import * as React from "react"
import PropTypes from "prop-types"
import PressIntro from "./pressIntro"

const Social = props => (
  <div>
    <h2 className={""}>Social</h2>
    <p>
      Follow Zycada on Twitter:{" "}
      <a
        className={"text-zycadaDarkPurple"}
        target={"_blank"}
        href={"https://www.twitter.com/zycada"}
      >
        @Zycada
      </a>
    </p>
    <p>
      Follow Zycada on LinkedIn:{" "}
      <a
        className={"text-zycadaDarkPurple"}
        target={"_blank"}
        href={"https://www.linkedin.com/company/zycada-networks/"}
      >
        https://www.linkedin.com/company/zycada-networks/
      </a>
    </p>
  </div>
)

export default Social
