import CaseStudyTitle from "../../../../src/components/caseStudyTitle";
import CaseStudyIntroContainer from "../../../../src/components/caseStudyIntroContainer";
import CaseStudyTestimonial from "../../../../src/components/caseStudyTestimonial";
import clayton from "../../../../content/casestudies/daneson/claytonHon300.jpg";
import danesonLogo from "../../../../content/casestudies/daneson/danesonLogoColor.webp";
import * as React from 'react';
export default {
  CaseStudyTitle,
  CaseStudyIntroContainer,
  CaseStudyTestimonial,
  clayton,
  danesonLogo,
  React
};