import * as React from "react"
import PropTypes from "prop-types"


const ArchitectureDiagram = (props) => (
<div className="flex min-w-4/5 max-w-4/5 justify-center mx-auto my-4 p-6 bg-white/20 rounded-5xl border border-white ">
    <div className="">
      <img className={""} src={props.imgURL1} alt={props.altText1}/>
    </div>
</div>
)


ArchitectureDiagram.propTypes = {
  imgURL1: PropTypes.string,
  altText1: PropTypes.string,
  
}

ArchitectureDiagram.defaultProps = {
  imgURL1: ``,
  altText1: ``,
 
}

export default ArchitectureDiagram
