import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import BlogImageGallery from "../../../../src/components/blogImageGallery";
import ArchitectureDiagram from "../../../../src/components/architectureDiagram";
import SoloContactForm from "../../../../src/components/soloContactForm";
import akamai from "../../../../content/manage-your-cdn-outage-crisis-during-shopping-season/cdnOutageAkamaiPostContent.png";
import cloudflare from "../../../../content/manage-your-cdn-outage-crisis-during-shopping-season/cdnOutageCloudflarePostContent.png";
import fastly from "../../../../content/manage-your-cdn-outage-crisis-during-shopping-season/cdnOutageFastlyPostContent.png";
import dnsDirector from "../../../../content/manage-your-cdn-outage-crisis-during-shopping-season/dnsDirectorArchitecture.png";
import * as React from 'react';
export default {
  StaticImage,
  GatsbyImage,
  BlogImageGallery,
  ArchitectureDiagram,
  SoloContactForm,
  akamai,
  cloudflare,
  fastly,
  dnsDirector,
  React
};