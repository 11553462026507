import CaseStudyTitle from "../../../../src/components/caseStudyTitle";
import CaseStudyIntroContainer from "../../../../src/components/caseStudyIntroContainer";
import CaseStudyTestimonial from "../../../../src/components/caseStudyTestimonial";
import mikhail from "../../../../content/casestudies/komuso/mikhailZhuro300.png";
import komusoDesignLogo from "../../../../content/casestudies/komuso/komusoDesignLogoColor300.png";
import * as React from 'react';
export default {
  CaseStudyTitle,
  CaseStudyIntroContainer,
  CaseStudyTestimonial,
  mikhail,
  komusoDesignLogo,
  React
};