import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import WhiteButton from "./WhiteButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"


const CaseStudyChallengeBlock = (props) => (
  <div>
    <div>
      <h4 className={"uppercase"}>Challenge</h4>
      <h3 className={"mb-0"}>{props.challengeSubHead}</h3>
    </div>
    <br />
    <div>
      <p>{props.challengePara1}</p>
      { props.challengePara2 && <p>{props.challengePara2}</p>}
      { props.challengePara3 && <p>{props.challengePara3}</p>}
      { props.challengePara4 && <p>{props.challengePara4}</p>}
      { props.challengePara5 && <p>{props.challengePara5}</p>}


    </div>
  </div>
)

CaseStudyChallengeBlock.propTypes = {
  challengeSubHead: PropTypes.string,
  challengePara1: PropTypes.string,
  challengePara2: PropTypes.string,
  challengePara3: PropTypes.string,
  challengePara4: PropTypes.string,
  challengePara5: PropTypes.string,



}

CaseStudyChallengeBlock.defaultProps = {
  challengeSubHead: ``,
  challengePara1: ``,
  challengePara2: ``,
  challengePara3: ``,
  challengePara4: ``,
  challengePara5: ``,
}


export default CaseStudyChallengeBlock

