import CaseStudyTitle from "../../../../src/components/caseStudyTitle";
import CaseStudyIntroContainer from "../../../../src/components/caseStudyIntroContainer";
import CaseStudyTestimonial from "../../../../src/components/caseStudyTestimonial";
import halfdan from "../../../../content/casestudies/jenshansen/jensHansenHalfDanHansen300.jpg";
import jensHansenLogo from "../../../../content/casestudies/jenshansen/jensHansenLogoColor300.png";
import * as React from 'react';
export default {
  CaseStudyTitle,
  CaseStudyIntroContainer,
  CaseStudyTestimonial,
  halfdan,
  jensHansenLogo,
  React
};