import CaseStudyTitle from "../../../../src/components/caseStudyTitle";
import CaseStudyIntroContainer from "../../../../src/components/caseStudyIntroContainer";
import CaseStudyTestimonial from "../../../../src/components/caseStudyTestimonial";
import tomislav from "../../../../content/casestudies/medicaldirect/tomislavBulat300.png";
import medialDirectLogo from "../../../../content/casestudies/medicaldirect/medicalDirectLogoColor300.png";
import * as React from 'react';
export default {
  CaseStudyTitle,
  CaseStudyIntroContainer,
  CaseStudyTestimonial,
  tomislav,
  medialDirectLogo,
  React
};