import * as React from "react"
import tw, { styled } from "twin.macro"


const Button = tw.button`
// w-40
// sm:ml-4
// sm:-top-1.5
// active:-mx-5px
// active:my-5px
relative 
text-white 
p-2
h-10
bg-btn-klean
border 
border-solid
border-transparent
rounded-md
focus:outline-none
hover:bottom-1
active:shadow-3DClicked
active:top-5px
active:-left-5px
font-bold
transition
transition-all
ease-in-out

`

export default Button
