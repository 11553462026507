import * as React from "react"
import tw, { styled } from "twin.macro"


const WhiteButton = tw.button`
// w-40
// sm:ml-4
// sm:-top-1.5
relative
text-black
hover:text-white
border
border-black
hover:border-white
p-2
h-10
bg-white
hover:bg-black
focus:outline-none
rounded-none

hover:
active:shadow-3DClicked
// active:-mx-5px
// active:my-5px
active:top-5px
active:-left-5px
font-bold
transition-all

`

export default WhiteButton
