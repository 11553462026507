import * as React from "react"
import PropTypes from "prop-types"

const PressIntro = props => (
  <div>
      <h1 className={"text-zycadaDarkPurple"}>{props.introTitle}</h1>
    <br />
      <p className={"text-violet-500 "}>{props.introPara1}</p>
  </div>
)

PressIntro.propTypes = {
  introTitle: PropTypes.string,
  introPara1: PropTypes.string,
  introPara2: PropTypes.string,
  introPara3: PropTypes.string,
  introPara4: PropTypes.string,
}

PressIntro.defaultProps = {
  introTitle: ``,
  introPara1: ``,
  introPara2: ``,
  introPara3: ``,
  introPara4: ``,
}

export default PressIntro
