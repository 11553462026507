import CaseStudyTitle from "../../../../src/components/caseStudyTitle";
import CaseStudyIntroContainer from "../../../../src/components/caseStudyIntroContainer";
import CaseStudyTestimonial from "../../../../src/components/caseStudyTestimonial";
import pete from "../../../../content/casestudies/livingspaces/peteFranco300.png";
import livingSpacesLogo from "../../../../content/casestudies/livingspaces/livingSpacesLogoColor300.png";
import * as React from 'react';
export default {
  CaseStudyTitle,
  CaseStudyIntroContainer,
  CaseStudyTestimonial,
  pete,
  livingSpacesLogo,
  React
};