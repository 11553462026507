import * as React from "react"
import PropTypes from "prop-types"
import PressIntro from "./pressIntro"

const About = props => (
  <div>
    <h2 className={""}>About Zycada</h2>
    <p>
      Zycada empowers the fastest online shopping experience with app-aware
      technology. Zycada Cloud Service Accelerator anticipates user
      interactions, working ahead of them as a personal concierge – dramatically
      enhancing the shopper’s experience and increasing online retail revenues.
      Zycada is backed by Khosla Ventures, Cervin Ventures and Nordic Eye
      Venture Capital. The company is headquartered in San Jose, California. To
      learn more, visit www.zycada.com and follow us on Twitter{" "}
      <a
        className={"text-zycadaDarkPurple"}
        target={"_blank"}
        href={"https://www.twitter.com/zycada"}
      >
        @Zycada
      </a>
      .
    </p>
  </div>
)

export default About
