import CaseStudyTitle from "../../../../src/components/caseStudyTitle";
import CaseStudyIntroContainer from "../../../../src/components/caseStudyIntroContainer";
import CaseStudyTestimonial from "../../../../src/components/caseStudyTestimonial";
import ajitha from "../../../../content/casestudies/brother/ajithaNarayanan300.png";
import brotherLogo from "../../../../content/casestudies/brother/brotherLogoColor300.png";
import * as React from 'react';
export default {
  CaseStudyTitle,
  CaseStudyIntroContainer,
  CaseStudyTestimonial,
  ajitha,
  brotherLogo,
  React
};