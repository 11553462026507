import * as React from "react"
import PropTypes from "prop-types"


const BlogImageGallery = (props) => (
  <div className="flex flex-col sm:flex-row justify-between items-center p-0 mx-auto sm:mx-4">
    
    <div className="min-w-32 border border-transparent overflow-hidden mx-4 sm:my-0">
      <img className={"rounded-2xl "} src={props.imgURL1} alt={props.altText1}/>
    </div>
    <div className="min-w-32 border border-transparent overflow-hidden mx-4 sm:my-0">
      <img className={"rounded-2xl"} src={props.imgURL2} alt={props.altText2}/>
    </div>
    <div className="min-w-32 border border-transparent overflow-hidden mx-4 sm:my-0">
      <img className={"rounded-2xl"} src={props.imgURL3} alt={props.altText3}/>
    </div>
</div>
)


BlogImageGallery.propTypes = {
  imgURL1: PropTypes.string,
  imgURL2: PropTypes.string,
  imgURL3: PropTypes.string,
  altText1: PropTypes.string,
  altText2: PropTypes.string,
  altText3: PropTypes.string,
  
}

BlogImageGallery.defaultProps = {
  imgURL1: ``,
  imgURL2: ``,
  imgURL3: ``,
  altText1: ``,
  altText2: ``,
  altText3: ``,
  
}

export default BlogImageGallery
