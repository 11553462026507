import * as React from "react"
import PropTypes from "prop-types"

const CaseStudyIntro = props => (
  <div>
    {props.introPara1}
    <br />
    <br />
    {props.introPara2}
    <br />
    <br />
    {props.introPara3}
    <br />
    <br />
    {props.introPara4}
  </div>
)

CaseStudyIntro.propTypes = {
  introPara1: PropTypes.string,
  introPara2: PropTypes.string,
  introPara3: PropTypes.string,
  introPara4: PropTypes.string,
}

CaseStudyIntro.defaultProps = {
  introPara1: ``,
  introPara2: ``,
  introPara3: ``,
  introPara4: ``,
}

export default CaseStudyIntro
