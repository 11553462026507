import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Button from "./button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import OrangeButtonWithWhiteLineShadow from "./orangeButtonWithWhiteLineShadow";
import { slide as Menu } from "react-burger-menu";
import { navigate } from "gatsby";
import ReactPlayer from "react-player";
import Modal from "react-modal";
import NewEvalForm from "./newEvalForm";

const SoloContactForm = ({}) => (
  <div className=''>
    <div className=''>
      <div className='flex flex-row flex-wrap justify-center'>
        <div className={"mx-4 min-w-1/4 py-0 my-0 md:py-6 md:my-12 bg-white/20 rounded-5xl border border-white"}>
          <NewEvalForm></NewEvalForm>
        </div>
        <div className='lg:px-16 py-2 my-2 md:py-10 lg:my-12 '>
          <div className='flex pb-10 flex-row items-center'>
            <div className={"zycadaIconWrapper flex text-center"}>
              <div className={"zycadaIcon text-violet-400"}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='feather feather-mail'
                >
                  <path d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z'></path>
                  <polyline points='22,6 12,13 2,6'></polyline>
                </svg>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div className={"flex flex-col justify-center"}>
              <p className='pt-0 pb-0 mb-0'>Email</p>
              <p className='pt-0 pb-0 mb-0'>sales@zycada.com </p>
            </div>
          </div>
          <div className=' flex flex-row items-center'>
            <div className={"zycadaIconWrapper flex text-center"}>
              <div className={"zycadaIcon text-violet-400"}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  stroke-width='2'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  className='feather feather-map-pin'
                >
                  <path d='M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z'></path>
                  <circle cx='12' cy='10' r='3'></circle>
                </svg>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div className={"flex flex-col justify-center"}>
              <p className='pt-0 pb-0 mb-0'>Location</p>
              <p className='pt-0 pb-0 mb-0'>75 E Santa Clara St</p>
              <p className='pt-0 pb-0 mb-0'>Ste 900</p>
              <p className='pt-0 pb-0 mb-0'>San Jose, CA 95113</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SoloContactForm;
