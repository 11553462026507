import * as React from "react"
import PropTypes from "prop-types"
import CaseStudyTitle from "./caseStudyTitle"
import CaseStudyIntro from "./caseStudyIntro"
import ResultsBlock from "./resultsBlock"
import CaseStudyChallengeBlock from "./caseStudyChallengeBlock"

const CaseStudyIntroContainer = props => (
  <div>
    <div>
      <div>
        <CaseStudyTitle />
      </div>
      <div className={"md:float-right"}>
        <ResultsBlock
          boost1={props.boost1}
          boost2={props.boost2}
          boost1Name={props.boost1Name}
          boost2Name={props.boost2Name}
          summaryText={props.summaryText}
        />
      </div>
      <div>
        <CaseStudyIntro
          introPara1={props.introPara1}
          introPara2={props.introPara2}
          introPara3={props.introPara3}
        />
        <br />
        <CaseStudyChallengeBlock
          challengeSubHead={props.challengeSubHead}
          challengePara1={props.challengePara1}
          challengePara2={props.challengePara2}
          challengePara3={props.challengePara3}
          challengePara4={props.challengePara4}
          challengePara5={props.challengePara5}
        />
      </div>
    </div>
  </div>
)

CaseStudyIntroContainer.propTypes = {
  introPara1: PropTypes.string,
  introPara2: PropTypes.string,
}

CaseStudyIntroContainer.defaultProps = {
  introPara1: ``,
  introPara2: ``,
}

export default CaseStudyIntroContainer
